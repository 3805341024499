import {
  Configuration,
  AdminAuthApi,
  MemberAuthApi,
  PartnerAuthApi,
  PartnerDashboardApi,
  PartnerGuideApi,
  PartnerNoticeApi,
  PartnerOrderApi,
  PartnerProductApi,
  PartnerQnaApi,
  CommonLotControllerApi,
  PartnerMakeOfferApi,
  PartnerMultiProductApi,
  PartnerMemberApi
} from '@/openapi';

const configuration = new Configuration({
  basePath: process.env.VUE_APP_API_HOST,
});

class PartnerAPI {
  product = new PartnerProductApi(configuration);
  adminAuth = new AdminAuthApi(configuration);
  memberAuth = new MemberAuthApi(configuration);
  partnerAuth = new PartnerAuthApi(configuration);
  partnerDashboard = new PartnerDashboardApi(configuration);
  partnerGuide = new PartnerGuideApi(configuration);
  partnerNotice = new PartnerNoticeApi(configuration);
  partnerOrder = new PartnerOrderApi(configuration);
  partnerProduct = new PartnerProductApi(configuration);
  partnerQna = new PartnerQnaApi(configuration);
  partnerMakeOffer =new PartnerMakeOfferApi(configuration);
  commonLot = new CommonLotControllerApi(configuration);
  multiProduct = new PartnerMultiProductApi(configuration);
  partnerMember = new PartnerMemberApi(configuration)
}

const partnerAPI = new PartnerAPI();

export default partnerAPI;
